/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { IRole } from '+types';
import { capitalizeFirst, filteredOutObjectProperty, getDate, getTime, history, isAllowed } from '+utils';

import Table from '../../Shared/Table';

import './index.scss';

const apiRequest = new APIRequest();

const RolesList = () => {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const status = searchQuery.value.status || [];

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, ['current', 'limit', 'status'])
  };

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery(['ROLES', page, limit, sortingParams], () => apiRequest.getAllRoles(Number(page), Number(limit), sortingParams), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        title: 'Roles',
        message: 'There has been an error getting roles',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const renderRoles = {
    isRowClickable: isAllowed(userAccess, ['custom_roles.update', 'my_custom_roles.update', 'system_roles.update']),
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no roles yet.',
    annotations: 'role(s)',
    rowURL: `/dashboard/users/roles`,
    rowKey: 'id',
    fields: (value: IRole) => ({
      data: {
        role: (
          <span role="button" tabIndex={0} className="smaller no-ellipsis">
            {capitalizeFirst(value?.name)}
          </span>
        ),
        event: (
          <span className="smaller no-ellipsis" style={{ color: 'rgb(35, 118, 243)' }} role="button" tabIndex={0}>
            <span>
              {`${value?.createdBy?.first_name ?? 'N/A'} ${value?.createdBy?.last_name ?? ''}`}{' '}
              {value?.createdBy?.email ? (
                <span className="user-email-list" style={{ color: '#979797' }}>
                  ({value?.createdBy?.email})
                </span>
              ) : (
                ''
              )}
            </span>
          </span>
        ),
        created_at: (
          <div style={{ margin: 0, padding: 0 }}>
            {getDate(value?.createdAt)}&nbsp;<span style={{ color: '#A9AFBC' }}>{getTime(value.createdAt)}</span>
          </div>
        ),
        last_modified: (
          <div style={{ margin: 0, padding: 0 }}>
            {getDate(value?.createdAt)}&nbsp;<span style={{ color: '#A9AFBC' }}>{getTime(value.createdAt)}</span>
          </div>
        )
      }
    })
  };
  const rolesTable = () => {
    return (
      <Table
        className="--users-table div-table-tablehead"
        hasPagination
        header={false}
        tableHeadings={['Role', 'Creator Name/Email', 'Date Created', 'Last Modified']}
        totalItems={paging?.total_items || 0}
        pageSize={paging?.page_size || 0}
        loading={isFetching}
        current={parseInt(page, 10)}
        actionFn={num => searchQuery.setQuery({ page: String(num) })}
        limitAction={no => searchQuery.setQuery({ limit: String(no) })}
        annotation="Roles"
        tableWrapperClassName="table-responsive"
        renderFields
        data={data}
        emptyStateHeading={renderRoles.emptyStateHeading || ''}
        emptyStateMessage={renderRoles.emptyStateMessage || ''}
        rowKey={renderRoles.rowKey}
        rowURL={renderRoles.rowURL}
        isRowClickable={renderRoles.isRowClickable as boolean}
        type="roles"
      >
        {renderRoles.fields}
      </Table>
    );
  };

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <div className="col-sm-12">
            <button type="button" className="btn btn-link mb-2 px-0" onClick={() => history.push('/dashboard/users', null)}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Back</span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 users__page">
            <div className="element-wrapper">
              <h6 className="element-header">Dashboard Roles</h6>
              <div>{rolesTable()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesList;
