import React, { Fragment, useLayoutEffect, useState } from 'react';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import { useReducerState, useSearchQuery } from '+hooks';
import {
  availableProductType,
  CurrencyType,
  modalState,
  productCategoriesPropTypeContentWithoutEnabled,
  productCategoriesType,
  ProductConfigType,
  updateProductConfigDataTypeWithoutEnabled
} from '+types';
import {
  availableCurrency,
  availableProductCategories,
  capitalize,
  capitalizeRemovedash,
  currentProductConfigSettings,
  filteredOutObjectProperty,
  productMapping
} from '+utils';

import EditDetailsCard from '../../components/EditDetailsCard';
import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';

import './index.scss';

const ProductCategories = ({
  config,
  type,
  currency,
  merchantId,
  merchantsStatus
}: {
  config: ProductConfigType;
  type: productCategoriesType;
  currency: CurrencyType;
  merchantId: string;
  merchantsStatus: boolean;
}) => {
  const searchQuery = useSearchQuery<{ productCategory: availableProductType }>();
  const [selectedContent, setSelectedContent] = useState<ProductConfigType | undefined>();
  const [state, setState] = useReducerState<{ activeModal: modalState }>({
    activeModal: null
  });
  const itemsToRemove = ['enabled'];
  const newConfig = config ? filteredOutObjectProperty(config, itemsToRemove) : {};
  const productCategory = searchQuery.value.productCategory ?? (Object.keys(newConfig)[0] as availableProductType) ?? '';

  useLayoutEffect(() => {
    if (config) {
      if ([availableCurrency.USD].includes(currency)) {
        itemsToRemove.push('bank_account');
      }

      setSelectedContent(newConfig[productCategory]);
    }
  }, [config]);

  const handleSelectTab = (key: Exclude<availableProductType, 'boolean'>, value: Exclude<ProductConfigType, 'boolean'>) => {
    searchQuery.setQuery({ productCategory: key });
    setSelectedContent(value);
  };

  return (
    <section className="product-cat__container">
      {Object.entries(filteredOutObjectProperty(config, itemsToRemove)).length > 0 ? (
        <>
          <div className="product-cat__container-menu">
            <p>Product categories</p>
            <ul>
              {config &&
                Object.entries(config).map(([key, value]) => {
                  if (key === 'enabled' || (['bank_account'].includes(key) && [availableCurrency.USD].includes(currency))) return null;
                  return (
                    <li className={productCategory === key ? '--active' : ''} key={key}>
                      <span
                        role="button"
                        onClick={() =>
                          handleSelectTab(
                            key as Exclude<availableProductType, 'boolean'>,
                            value as unknown as Exclude<ProductConfigType, 'boolean'>
                          )
                        }
                        onKeyDown={() =>
                          handleSelectTab(
                            key as Exclude<availableProductType, 'boolean'>,
                            value as unknown as Exclude<ProductConfigType, 'boolean'>
                          )
                        }
                        tabIndex={0}
                      >
                        {capitalizeRemovedash(key)}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>

          {selectedContent && productCategory ? (
            <div className="product-cat__container-content">
              <h5 className="content-header">{`${capitalizeRemovedash(productCategory)} Configuration`}</h5>
              <label className="content-action">
                <input
                  checked={selectedContent?.enabled}
                  type="checkbox"
                  onChange={() => setState({ activeModal: selectedContent?.enabled ? 'disable-channel' : 'enable-channel' })}
                  disabled={!merchantsStatus || !config.enabled}
                  style={{ cursor: `${!merchantsStatus || !config.enabled ? 'not-allowed' : 'pointer'}` }}
                />
                <span>{`Allow this merchant to  ${availableProductCategories[type] === availableProductCategories.payouts ? 'make' : 'receive'} ${capitalizeRemovedash(productCategory)} ${type} in ${currency}`}</span>
              </label>
              {Object.entries(selectedContent).map(([key, value]) => {
                if (
                  !currentProductConfigSettings.includes(key) ||
                  (['pay-ins'].includes(type) && ['transaction_limit'].includes(key) && ['disbursement_wallet'].includes(productCategory))
                )
                  return null;
                return (
                  <Fragment key={key}>
                    <EditDetailsCard
                      title={key as updateProductConfigDataTypeWithoutEnabled}
                      content={value as unknown as productCategoriesPropTypeContentWithoutEnabled}
                      paymentMethod={productCategory}
                      type={capitalize(productCategory)}
                      category={type}
                      merchantId={merchantId}
                      currency={currency}
                      disableEdit={merchantsStatus && config.enabled && selectedContent?.enabled}
                    />{' '}
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <EmptyStateComponent message="Channels Not Available" />
          )}
        </>
      ) : (
        <EmptyStateComponent message="Product Categories Not Available" />
      )}
      {state.activeModal && (
        <MerchantCurrencyModal
          activeModal={state.activeModal}
          setActiveModal={() => setState({ activeModal: null })}
          currency={currency}
          merchantId={merchantId}
          productType={productMapping[type]}
          method={productCategory}
          hideButton
        />
      )}
    </section>
  );
};

export default ProductCategories;
