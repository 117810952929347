import React from 'react';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Table from '+containers/Dashboard/Shared/Table';
import { useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { IdentityTablePropsT } from '+types';
import { capitalizeFirst, getDate, getTime, history, isAllowed } from '+utils';

import OfficeIcon from '+assets/img/dashboard/office-icon.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

interface IRequest {
  reference: string;
  name: string;
  status: 'pending' | 'approved' | 'declined';
  date_created: string;
  email: string;
}

export default function RequestsTable({ resolvedData, isFetching, refetch, exportData }: IdentityTablePropsT<IRequest>) {
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const currentPage = searchQuery?.value?.page || 1;
  const canShowExport = isAllowed(userAccess, ['identity_requests.export']);
  const canViewRequestDetails = isAllowed(userAccess, ['identity_request_details.view']);

  const getDetailsLink = (reference: string) => `/dashboard/identity/requests/${reference}`;
  const handleClick = (reference: string) => canViewRequestDetails && history.push(getDetailsLink(reference));

  const renderFields = (requests: IRequest[]) => {
    return (
      <div className="nav-content active">
        <div className="element-box-tp">
          <div>
            {requests?.map(each => {
              return (
                <div
                  key={`merchant_${each.name}`}
                  className="div-table --row irt"
                  role="button"
                  tabIndex={0}
                  onClick={() => handleClick(each.reference)}
                >
                  <div onKeyDown={() => handleClick(each.reference)}>
                    <span className="body-row-header">Merchant:</span>
                    <img src={OfficeIcon} alt="Office Icon" />

                    <span className="irt__merchant-name">{capitalizeFirst(each?.name) || 'N/A'}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Email Address:</span>
                    {each.email ? (
                      <Copyable
                        text={each?.email}
                        textToCopy={each?.email}
                        spanClassName="identity-copy-btn-span"
                        buttonClassName="identity-copy-btn"
                      />
                    ) : (
                      'N/A'
                    )}
                  </div>
                  <div>
                    <span className="body-row-header">Request Status:</span>
                    <span className={`service-status --${each?.status}`}></span>
                    <span>{capitalizeFirst(each.status || '') || 'N/A'}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Request Timestamp:</span>
                    <span>{getDate(each?.date_created)}</span>
                    <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                      {getTime(each?.date_created)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="element-box-tp mt-5">
      <Table
        className={'irt'}
        tableHeadings={['Merchant', 'Email Address', 'Request Status', 'Request Timestamp']}
        current={parseInt(currentPage + '', 10)}
        totalItems={resolvedData?.paging ? resolvedData.paging.total_items : 0}
        pageSize={resolvedData?.paging ? resolvedData.paging.page_size : 0}
        actionFn={current => searchQuery.setQuery({ page: String(current) })}
        limitAction={limit => searchQuery.setQuery({ limit: String(limit) })}
        annotation={'requests'}
        emptyStateHeading={`There are no requests yet.`}
        filterExportName={'Export Identity Requests'}
        filterName={`All merchants awaiting access approval (${resolvedData?.paging?.total_items || 0})`}
        filterExportAction={exportData}
        type={'identity-requests'}
        filterKeywordPlaceholder={'Search by Merchant ...'}
        filterTotalCount={resolvedData?.paging?.total_items}
        loading={isFetching}
        data={resolvedData?.data || []}
        filterHasAdvancedFilter
        filterShowExport={canShowExport as boolean}
        hasPagination
      >
        {renderFields(resolvedData?.data as IRequest[])}
      </Table>
    </section>
  );
}
